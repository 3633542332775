import Swiper from "swiper";
import { FreeMode, Navigation, Thumbs, Pagination, Autoplay, Grid } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";
import "swiper/css/grid";

export const HomeController = {
  init() {
    this.initMainSlider();
    this.initFeaturedProjectsCarousel();
    this.initFeaturedNewsSlides();
  },

  initMainSlider() {
    const thumbSliderEl = document.querySelector(".thumbSlider");
    const mainSliderEl = document.querySelector(".mainSlider");

    const thumbSlider = new Swiper(thumbSliderEl, {
      loop: true,
      spaceBetween: 0,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
      modules: [FreeMode, Thumbs],
    });

    if (mainSliderEl) {
      const mainSlider = new Swiper(mainSliderEl, {
        loop: true,
        spaceBetween: 0,
        modules: [Autoplay, Navigation],
        speed: 500,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".mainSlider .swiper-button-next",
          prevEl: ".mainSlider .swiper-button-prev",
        },
      });
    }
  },

  initFeaturedNewsSlides() {
    const categoryButtons = document.querySelectorAll(".category-btn");
    const swiperContainers = document.querySelectorAll(".swiper-container.featured-news-slides");
  
    if (!swiperContainers.length) {
        return;
    }
  
    let activeSwiperInstances = {}; // Store Swiper instances
  
    swiperContainers.forEach((swiperContainer, index) => {
        // Look for category class that starts with 'category-' and is followed by category name
        const categoryMatch = swiperContainer.className.match(/category-([\w-]+)/);
  
        if (!categoryMatch) {
            return;
        }
  
        const category = categoryMatch[1]; // Extract category name
  
        activeSwiperInstances[category] = new Swiper(swiperContainer, {
            modules: [Autoplay, Navigation],
            allowTouchMove: true,
            loop: false,
            centeredSlides: false,
            speed: 500,
            spaceBetween: 20,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            slidesPerView: 1,
            slidesPerGroup: 1,
            breakpoints: {
                0: { slidesPerView: 1 },
                1021: { slidesPerView: 1 },
            },
            navigation: {
                nextEl: swiperContainer.querySelector(".swiper-button-next"),
                prevEl: swiperContainer.querySelector(".swiper-button-prev"),
            },
        });
  
        swiperContainer.style.display = index === 0 ? "block" : "none"; // Display first category slider
    });
  
    // Set the first button as active by default
    categoryButtons.forEach((btn, index) => {
        if (index === 0) btn.classList.add("active");
    });
  
    // Category button click handler
    categoryButtons.forEach((btn) => {
        btn.addEventListener("click", function () {
            let selectedCategory = this.getAttribute("data-category");
  
            // Hide all sliders
            swiperContainers.forEach((swiper) => {
                swiper.style.display = "none";
            });
  
            // Show the selected category slider
            const selectedSlider = document.querySelector(`.swiper-container.category-${selectedCategory}`);
            if (selectedSlider) {
                selectedSlider.style.display = "block";
            } else {
            }
  
            // Remove active class from all buttons and add to selected
            categoryButtons.forEach((button) => button.classList.remove("active"));
            this.classList.add("active");
        });
    });
  },

  initFeaturedProjectsCarousel() {
    const featuredProjectsCarouselEl = document.querySelector(".featuredProjectsCarousel");

    if (featuredProjectsCarouselEl) {
      new Swiper(featuredProjectsCarouselEl, {
        loop: true,
        centeredSlides: false,
        spaceBetween: 20,
        modules: [Autoplay, Pagination, Navigation],
          speed: 500,
          autoplay: {
              delay: 3000,
              disableOnInteraction: false,
          },
        pagination: {
          el: ".featuredProjectsPagination",
          type: "fraction",
        },
        slidesPerView: 2,
        breakpoints: {
          0: { slidesPerView: 1 },
          412: { slidesPerView: 1 },
          640: { slidesPerView: 3 },
          768: { slidesPerView: 3 },
          1024: { slidesPerView: 3 },
          1200: { slidesPerView: 5 },
        },
        navigation: {
          nextEl: ".buttons-container .swiper-button-next",
          prevEl: ".buttons-container .swiper-button-prev",
        },
      });
    }
  },
};
