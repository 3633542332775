export const NavController = {
  init() {
    return new Promise((resolve, reject) => {
      try {
        this.initMainMenu();

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },

  initMainMenu() {
    document.querySelectorAll(".menu-item-has-children > a").forEach((item) => {
      item.addEventListener("click", function (event) {
        event.preventDefault();
        const parentLi = this.parentElement; // get parent element
        parentLi.classList.toggle("active");

        // Close another submenu if open a new one
        document.querySelectorAll(".menu-item-has-children").forEach((li) => {
          if (li !== parentLi) {
            li.classList.remove("active"); // Close another submenus
          }
        });
      });
    });

    const hamburger = document.querySelector(".hamburger-icon");

    hamburger.addEventListener("click", function () {
      const menu = document.querySelector(".main-menu");
      const body = document.body;

      menu.classList.toggle("mobile-open");
      hamburger.classList.toggle("open");

      if (menu.classList.contains("mobile-open")) {
        body.style.overflow = "hidden";
        menu.style.display = "block";
      } else {
        body.style.overflow = "auto";
        menu.style.display = "none";
      }
    });

    window.onresize = function () {};
  },
};
