//const isPage = slug => document.body.classList.contains(slug);

export const UIController = {
  init() {
    return new Promise((resolve, reject) => {
      try {
        // if (isPage('home')) {
        // }

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
};
