import onDocumentLoad from "./helpers/on-document-load";
import { UIController } from "./controller/ui.controller";
import { NavController } from "./controller/nav.controller";
import { HomeController } from "./controller/homeController";

export const APP = {
  init() {
    return new Promise(async (resolve, reject) => {
      try {
        await UIController.init();

        await NavController.init();
        await HomeController.init();

        resolve();
      } catch(error) {
        reject(error);
      }
    })
  }
}

onDocumentLoad(APP.init);